import * as React from 'react';

function Background() {
  return (
    <svg
      width="1440"
      height="775"
      viewBox="0 0 1440 775"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M946.049 417.308H706.195c-58.101 0-117.692-30.553-117.692-116.25v-1.64V59.615C588.503 26.678 561.836 0 528.912 0H290.548c-32.924 0-59.591 26.678-59.591 59.615v238.462c0 32.937 26.667 59.615 59.591 59.615H529.21c59.442.149 118.884 59.765 118.884 117.741v239.952c0 32.937 26.667 59.615 59.591 59.615h238.364c32.924 0 59.591-26.678 59.591-59.615V476.923c0-32.937-26.667-59.615-59.591-59.615zM111.775 417.307h-238.364c-32.924 0-59.591 26.678-59.591 59.615v238.462c0 32.937 26.667 59.615 59.591 59.615h238.364c32.924 0 59.591-26.678 59.591-59.615V476.922c0-32.937-26.667-59.615-59.591-59.615z"
        fill="#F7F5FE"
      />
      <path
        d="M946.049 0H707.685c-32.924 0-59.591 26.678-59.591 59.615v238.462c0 32.937 26.667 59.615 59.591 59.615h238.364c32.924 0 59.591-26.678 59.591-59.615V59.615C1005.64 26.678 978.973 0 946.049 0zM1363.19 417.307h-238.37c-32.92 0-59.59 26.678-59.59 59.615v238.462c0 32.937 26.67 59.615 59.59 59.615h238.37c32.92 0 59.59-26.678 59.59-59.615V476.922c0-32.937-26.67-59.615-59.59-59.615z"
        fill="#F7F5FE"
      />
      <path
        d="M1780.32 417.308H1541.66c-59.44-.149-118.88-59.765-118.88-117.741v-.596V59.615c0-32.937-26.67-59.615-59.59-59.615h-238.37c-32.92 0-59.59 26.678-59.59 59.615v238.462c0 32.937 26.67 59.615 59.59 59.615h239.86c58.1 0 117.69 30.553 117.69 116.25V715.385c0 32.937 26.67 59.615 59.59 59.615h238.36c32.93 0 59.59-26.678 59.59-59.615V476.923c0-32.937-26.66-59.615-59.59-59.615z"
        fill="#F7F5FE"
      />
    </svg>
  );
}

const MemoBackground = React.memo(Background);
export default MemoBackground;
