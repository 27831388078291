import * as React from 'react';

function Klaviyo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.447 11.338l9.86-6.162a1.46 1.46 0 011.388 0l9.86 6.162c.396.182.552.624.368.988a.77.77 0 01-.367.347l-1.323.823c-3.226-4.81-9.99-6.249-15.09-3.198a10.573 10.573 0 00-3.391 3.198l-1.323-.823c-.386-.19-.543-.642-.34-1.006a.803.803 0 01.358-.329zm10.55-.07c-2.83.01-5.459 1.379-6.966 3.641l2.27 1.421c1.562-2.435 4.916-3.224 7.508-1.75a5.295 5.295 0 011.856 1.75l2.27-1.421c-1.489-2.27-4.117-3.649-6.938-3.64zm0 5.15c-1.002.008-1.92.51-2.408 1.334l1.526.936c.248.2.56.303.882.312.322 0 .643-.104.882-.312l1.526-.936c-.487-.823-1.406-1.335-2.408-1.335z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoKlaviyo = React.memo(Klaviyo);
export default MemoKlaviyo;
