import { SystemProps } from '@storyofams/react-ui';

interface TextOrObjectWithTextProps extends SystemProps {
  text: any;
}

export const TextOrObjectWithText = ({ text }: TextOrObjectWithTextProps) => {
  if (typeof text === 'string') {
    return (
      <>
        {text}
        <br />
      </>
    );
  }
  switch (text.type) {
    case 'doc':
      return text.content.map((docContent) => (
        <TextOrObjectWithText text={docContent} />
      ));
    case 'paragraph':
      return text.content.map((paragraphContent) => (
        <TextOrObjectWithText text={paragraphContent.text} />
      ));
    default:
      return null;
  }
};
