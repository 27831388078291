import * as React from 'react';

function Returns() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.12 44.56l2.64-2.64C68.88 57.6 55.92 70.08 40 70.08h-2v4h2c9.36 0 17.76-3.76 23.92-9.92 5.76-5.84 9.44-13.68 9.84-22.32l2.72 2.72 2.8-2.8-5.6-5.6-.08-.08-1.92-1.92-1.92 1.92-.08.08-5.6 5.6 3.04 2.8zm-54.24-8.64l-2.64 2.64C11.12 22.88 24.08 10.4 40 10.4h2v-4h-2c-9.36 0-17.76 3.76-23.92 9.92a33.657 33.657 0 00-9.84 22.32l-2.72-2.72-2.8 2.8 5.6 5.6.08.08 1.92 1.92 1.92-1.92.08-.08 5.6-5.6-3.04-2.8zM40 24l-16 6.5L40 37l16-6.5L40 24zM24 32V49.528L38.934 56V38.472l.066-.068L24 32zM40.07 38.472V56L56 49.528V32l-16 6.404.07.068z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoReturns = React.memo(Returns);
export default MemoReturns;
